<template>
    <Layout>
        <b-card>
            <FilterBase
                session="Form"
                :filter="filter"
                :button="true"
                url="/formularios/cadastrar"
            />

            <div class="mb-0 mt-3">
                <TableList
                    session="Form"
                    url="formularios/"
                    :table="table"
                    :keys="keys"
                    :key="tableKey"
                >

                    <template #title="{value}">

                        <router-link :to="'/formularios/cadastrar/' + value.id">

                            <div class="media flex-nowrap align-items-center"
                                 style="white-space: nowrap;">
                                <div class="media-body">

                                    <div class="d-flex flex-column">
                                        <p class="mb-0">
                                            <strong class="js-lists-values-name text-link">{{
                                                    value.title
                                                }}</strong>
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </router-link>

                    </template>

                    <template #total_questions="{value}">
                        <span>{{ value.total_questions || 0 }}</span>
                    </template>

                    <template #actions="{value}">
                        <Actions
                            session="Form"
                            :id="value.id"
                            :types="types"
                            @refreshTable="refreshTable()"
                            api="forms/"
                            url="formularios/"
                        />
                        <a :href="`${env.menteeUrl}formulario/preview/${encondeId(value.id)}`" target="_blank">
                            <i class="fa fa-search text-muted mx-1" title="Preview"></i>
                        </a>
                    </template>

                </TableList>
            </div>
        </b-card>
    </Layout>

</template>

<script>
/* eslint-disable */
import Layout from '@/components/layout/main.vue';
import TableList from "@/components/base/table-list.vue";
import FilterBase from '@/components/base/filter-component.vue';
import Actions from "@/components/base/actions.vue";
import {filter} from "@/views/forms/js/filter";
import {keys, table} from "@/views/forms/js/table";
import {setSessions} from "@/components/composables/setSessions";
import env from '@/env'
import {encondeId} from "@/components/composables/functions";

export default {
    components: {
        Layout,
        FilterBase,
        TableList,
        Actions
    },
    methods: {
        encondeId,
        refreshTable() {
            this.tableKey++;
        }
    },
    data() {
        return {
            types: ['edit', 'delete'],
            tableKey: 0,
            filter,
            table,
            keys,
            env
        }
    },
    mounted() {
        this.$store.commit('form/resetQuestions');
        if (!localStorage.getItem('Form')) setSessions('Form');
    }
}
</script>
